.sign-in-and-sign-up {
    width: 33vw;
    display: flex;
    justify-content: center;
    margin: 30px 0 30px 150px;
    height: 70vh;
    border: 1px solid #222;
}
.login{
    display: flex;
    img{
        margin-left: 50px;
        margin-top: 50px;
    }
}

@media screen and (max-width: 800px){
    .sign-in-and-sign-up{
        width: none;
        margin: 18px;
        height: 92vh;
    }
    .login {
        img {
            display: none;
        }
    }
    
}