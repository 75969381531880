body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.color-white {
  color: #fff;
}

::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background: #fff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #3f51b5;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #222;
  cursor: pointer;
}

.swal__password--popup {
  border: 1px solid #222;
  background-color: #fffffe;
  box-shadow: 0px 8px 16px 3px #222;
}

.swal__password--title {
  color: #094067;
}

.swal__password--input {
  background-color: #f2f2f2;
}

.swal__password--confirm-button {
  color: #094067 !important;
  background-color: #3da9fc;
}

.swal__password--cancel-button {
  background-color: #ef4565;
  color: #094067 !important;
}
