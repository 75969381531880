.sign-in {
    width: 80%;
    display: flex;
    flex-direction: column;

    .title {
        margin: 10px 0;
    }

    .buttons {
        display: flex;
        position: relative;
        top:50px;
    }
}

@media screen and (max-width: 800px){
    .sign-in{
        left:49px
    }
}